import React, { FC, HTMLAttributes } from "react"
import { COMPARE_IMAGES } from "@/components/Catalog/Categories/Categories"
import { ICategoryTreeItem } from "@/types"
import { EntityImage } from "@/ui/EntityImage/EntityImage"
import { isParentCategory } from "@/utils/categories/helpers"
import { StyledCategory, StyledCategoryImage } from "./StyledCategory"
import { SubCategory } from "./SubCategory"
import { Title } from "./Title"

export type CategoryPropsType = {
  category: ICategoryTreeItem
  isShowImage?: boolean
} & HTMLAttributes<HTMLElement>

export const Category: FC<CategoryPropsType> = ({
  category,
  isShowImage = false,
}) => {
  const isShowCategory = (category?.product_qty || 0) > 0

  if (!isShowCategory) {
    return null
  }

  const categoriesChilds = Object.entries(category.children).map(
    ([, child]) => child,
  )

  return (
    <StyledCategory data-id={category.uuid} data-weight={category.weight || 0}>
      {(isParentCategory({
        parent: category.parent || [],
      }) ||
        isShowImage) && (
        <>
          <StyledCategoryImage>
            <EntityImage
              imagePath={
                !!category.image
                  ? category.image
                  : COMPARE_IMAGES[category.uuid || ""] || undefined
              }
              imageAlt={category.name || ""}
              width={80}
              height={80}
              layout={"intrinsic"}
              objectFit={"cover"}
            />
          </StyledCategoryImage>
        </>
      )}

      <Title
        category={category}
        parent={category?.parent}
        path={category.alias || ""}
      />

      <SubCategory categories={categoriesChilds} />
    </StyledCategory>
  )
}

Category.displayName = "Category"
